import { RouteProps } from "react-router-dom";
import { PageSpinner } from "./components/PageSpinner";
import { useSelector } from "./store/hooks";

export const ProtectedRoute = (props: RouteProps) => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  if (!isLoggedIn) {
    return <PageSpinner />;
  }

  return <>{props.children}</>;
};
