import { RefObject, useRef, useEffect } from "react";

export const useClickOutside = (
  onClick: Function
): RefObject<HTMLDivElement> => {
  const node: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = (e: MouseEvent): void => {
      const { current } = node;
      if (!current || current.contains(e.target as Node)) {
        return;
      }
      onClick();
    };

    document.addEventListener("mousedown", handleClick);
    return (): void => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [onClick]);

  return node;
};
